import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import brandingFeaturedImage from "../../../static/branding/bekal-aquatics/bekal-aquatics-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/bekal-aquatics.scss"
import AquaticsFullwidth from "../../components/branding/bekal-aquatics/aquaticsFullWidth"
import AquaticsMain from "../../components/branding/bekal-aquatics/aquaticsMain"
import AquaticsMoreBranding from "../../components/branding/bekal-aquatics/aquaticsMoreBranding"


export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Bekal Aquatics: Diving into Brand Design | WowMakers"
            description="Plunge into our dynamic branding for Bekal Aquatics. We crystallized their adventurous spirit in every brand element."
        />
    </>
)

const bekalAquaticsBranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-bekal-aquatics"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <AquaticsMain />
            <AquaticsFullwidth />
            <AquaticsMoreBranding />
        </Layout>
    )
}

export default bekalAquaticsBranding
